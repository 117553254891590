.product:after {
    content: '...';
    display: inline-block;
    color: #000;
    font-size: 20px;
    position: relative;
    top: -102px;
    left: 122px;
}
.product {
    width: 200px;
      text-overflow: ellipsis;
    overflow: hidden;
   height: 4.4em;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    padding: 0 0.5em;
}